<template>
  <div class="menu d-flex d-col">
    <div class="grow d-flex pt-15 pl-20">
      <div class="account b-box item">
        <div>{{ $t("toolbars.account.account") }}</div>
        <div class="pl-10 mt-10 font-14">
          <div v-if="!isAdmin"  class="opacity-60">{{ $t("toolbars.account.name") }}</div>
          <div class="mt-5">{{ firstName }} {{ lastName }}</div>
          <div class="opacity-60 mt-10">{{ $t("toolbars.account.email") }}</div>
          <div class="mt-5">{{ email }}</div>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-end pa-15 pr-25 opacity-60">
      <button class="pointer" @click="logout">
        {{ $t("toolbars.account.logout") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {
    logout() {
      if (this.isPartners) {
        this.$store.dispatch("user/logout");
        this.$router.push("/partners/login");
      } else if (this.$route.path.includes("admin")) {
        this.$store.dispatch("user/logout");
        this.$router.push("/admin/login");
      }
      else {
        this.$store.dispatch("user/logout");
        this.$router.push("/login");
      }
    },
  },
  computed: {
    isPartners() {
      return this.$route.path.includes("partners");
    },
    isAdmin() {
      return this.$route.path.includes("admin");
    },
    ...mapState("user", ["email", "firstName", "lastName"]),
  },
};
</script>

<style scoped>
.menu {
  min-height: 200px;
  background-color: #141925;
  position: fixed;
  top: 59px;
  z-index: 4;
  right: 0;
}

.item {
  width: 240px;
}

button:focus {
  text-decoration: underline;
  font-weight: 600;
  background-color: initial;
}

button:hover {
  font-weight: 600;
  background-color: initial;
}

@media only screen and (max-width: 740px) {
  .item {
    width: 190px;
  }
}
</style>