<template>
  <div class="relative ">
    <div class="center h100 ">
      <button @click="toggle" ref="button" class="holder center mr-10">
      <img class="flag" :src="'/img/static/' + lang + '-flag.svg'" alt="" />
      <span class="uppercase ml-10">{{ lang }}</span>
    </button>
    </div>
    <transition name="dropdown">
      <div v-if="show" class="dropdown" :style="styling">
        <RadioFlags
          v-hotkey="{ esc: close }"
          v-outside="toggle"
          v-model="selection"
          :options="options"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RadioFlags from "@/components/Atoms/RadioFlags.vue";
export default {
  props: ["center"],
  components: {
    RadioFlags,
  },
  data() {
    return {
      active: 0,
      selection: "en",
      show: false,
      ignore: true,
    };
  },
  methods: {
    langIndex(val) {
      return this.options.indexOf(val);
    },
    setLang(val) {
      this.$store.commit("user/setLang", val);
      if (this.email) this.$store.dispatch("user/setLang", val);
      if (!this.email) this.$store.dispatch("user/setLangGuest", val);
      this.$i18n.locale = val;
      this.active = this.langIndex(val);
      this.selection = val;
    },
    close() {
      this.show = false;
    },
    toggle() {
      this.show = !this.show;
    },
  },
  computed: {
    styling: function () {
      if (this.center == "") {
        return { right: "-59px" };
      }
    },
    options() {
      return process.env.VUE_APP_BUILD == "prod"
        ? ["en", "de", "sk"]
        : [
            "de",
            "en",
            "es",
            "el",
            "fr",
            "nl",
            "pl",
            "pt",
            "sk",
            "zh-cn",
            "zh-tw",
            "hi",
          ];
    },
    ...mapState("user", ["lang", "email"]),
  },
  watch: {
    lang(val) {
      if (this.selection == this.lang) return;
      this.setLang(val);
    },
    selection(val) {
      if (this.ignore) return;
      this.setLang(val);
      this.close();
    },
  },
  async mounted() {
    this.popupItem = this.$refs.button;
    this.selection = this.lang;
    this.ignore = true;
    setTimeout(() => {
      this.ignore = false;
    }, 0);
  },
};
</script>

<style scoped>
.holder {
  border-radius: 3px;
  border: solid 1px #42515e;
  height: 40px;
  width: 80px;
  cursor: pointer;
  min-width: max-content;
  padding: 0px 10px;
  box-sizing: border-box;
}

.flag {
  max-height: 24px;
  max-width: 24px;
}

.holder:hover {
  background-color: #233748;
}

.dropdown {
  background-color: #141925;
  width: 220px;
  position: absolute;
  right: 0;
}

.langSelector:focus {
  outline: none;
  background-color: #233748;
}

@media only screen and (max-width: 576px) {
  .dropdown {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .holder {
    border-radius: 3px;
    border: solid 1px #42515e;
    height: 40px;
    width: 80px;
    cursor: pointer;
   
    margin-right: 10px;
  }
}
</style>