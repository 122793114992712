<template>
  <div class="pb-60">
    <div class="top_toolabr noselect">
      <div class="logo_container">
        <button @click="home" class="center logoButton">
          <img
            class="logo pointer"
            :title="tip && $t('toolbars.topToolbar.home')"
            src="/img/static/logo.svg"
            alt="logo"
          />
        </button>
        <div class="mobile_name grow ml-10">
          <div class="row center">secur</div>
          <div class="bold row center">Portal</div>
        </div>
        <button @click="toggle" class="mobile_chevron">
          <s-icon color="green">menu</s-icon>
        </button>
      </div>
      <div class="grow align-center justify-end">
      </div>
      <button
        v-if="email"
        class="name pointer"
        @click="toggleMenu"
        v-hotkey="{ esc: closeMenu }"
        :title="tip && $t('toolbars.topToolbar.account')"
      >
        <section class="center ml-20">
          <div class="relative">
            <div class="profile">
              <div v-if="!isPartners">
                <img class="pic" v-if="avatar" :src="url" />
                <s-icon height="30" v-if="!avatar">account</s-icon>
              </div>
              <div v-if="isPartners">
                <img class="pic" v-if="hash" :src="url" />
                <s-icon height="30" v-if="!hash">account</s-icon>
              </div>
            </div>
          </div>
        </section>
        <div v-if="!isAdmin && !isPartners" class="user_name">
          {{ fullName }}
        </div>
        <div v-if="isAdmin" class="user_name">{{ "Administrator" }}</div>
        <div v-if="isPartners && !isAdmin" class="user_name">
          {{ company.companyName }}
        </div>
        <img
          class="chev_down white_svg"
          src="/img/icons/chevron-down.svg"
          alt="logo"
        />
      </button>
      <section v-if="!email" class="name">
        <router-link
          class="d-flex white--text w100 h100 center"
          :to="isPartners ? '/partners/login' : '/login'"
        >
          <s-icon width="16" color="white">account</s-icon>
          <div class="mx-5 capital">{{ $t("registration.login") }}</div>
        </router-link>
      </section>
    </div>
    <transition name="dropdown">
      <Menu v-show="menu" v-click-outside:name="closeMenu" class="menu"></Menu>
    </transition>
  </div>
</template>

<script>
import Menu from "./AccountDropDown";
import LanguageSelector from "@/components/Atoms/LanguageSelector";
import { mapState } from "vuex";

export default {
  components: {
    Menu,
    LanguageSelector,
  },
  data() {
    return {
      menu: false,
      cacheKey: 1,
    };
  },
  methods: {
    toggle() {
      let auth = this.email;
      if (auth) this.$store.commit("toolbars/toggleSidebar");
    },
    home() {
      try {
        if (this.isPartners) return this.$router.push("/partners").catch(() => {});
        else if (this.isAdmin) return this.$router.push("/admin").catch(() => {});
        else return this.$router.push("/").catch(() => {});
      } catch {}
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    closeMenu() {
      this.menu = false;
    },
  },
  computed: {
    url() {
      return this.isPartners ? this.logoUrl : this.avatarUrl;
    },
    isPartners() {
      return this.$route.path.includes("partners");
    },
    isAdmin() {
      return this.$route.path.includes("admin");
    },
    avatarUrl() {
      let reseller = this.isPartners ? "/reseller" : "";
      return (
        process.env.VUE_APP_URL + "/api" + reseller + "/avatar/" + this.avatar
      );
    },
    logoUrl() {
      let domain = process.env.VUE_APP_URL + "/api/reseller/logo/";
      let url = domain + this.hash + "?" + this.cacheVue;
      return url;
    },
    ...mapState("user", [
      "email",
      "fullName",
      "lang",
      "avatar",
      "tip",
      "company",
      "hash",
      "cacheVue",
    ]),
    ...mapState("toolbars", ["sidebar"]),
  },
  watch: {
    immediate: true,
    account(val) {
      this.closeMenu();
    },
    billing(val) {
      this.closeMenu();
    },
    $mq(val) {
      if (val == "sm") this.closeMenu();
    },
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.top_toolabr {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #1d2535;
  position: fixed;
  z-index: 4;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0s;
  width: 60px;
  height: 60px;
  background-color: var(--d2-blue);
}

.logo {
  height: 43px;
  width: 40px;
  min-width: 40px;
}

.logoButton:focus,
.logoButton:hover {
  background-color: #00000000;
  transform: scale(1.05);
  filter: brightness(1.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
}

.chevron_double {
  min-width: 24px;
}

.chev_down {
  margin-right: 10px;
  height: 21px;
  width: 21px;
  min-width: 21px;
}

.main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: var(--green);
  font-size: 14px;
  margin-left: 13px;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 197px;
  background-color: #0000004d;
  font-size: 14px;
  color: var(--white);
}

.name:hover {
  background-color: #ffffff10;
}

.name:focus {
  background-color: #cccccc10;
}

.user_name {
  margin-right: 10px;
  margin-left: 10px;
  text-align: end;
  width: 100%;
}

.alarm {
  margin-right: 5px;
  min-width: 24px;
}

.login {
  border: solid 1px #42515e;
  border-radius: 5px;
  padding: 7px;
  background-color: var(--green);
}

.mobile_name {
  display: none;
  width: 114px;
  height: 32px;
  font-size: 24px;
  font-weight: 300;
  color: var(--green);
}

.mobile_chevron {
  color: var(--white);
  margin-left: auto;
  display: none;
  margin-right: 20px;
}

.profile {
  background-color: grey;
  height: 30px;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .main {
    display: none;
  }

  .name {
    display: none;
  }

  .chevron_double {
    display: none;
  }

  .logo_container {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
    transition: all ease 0.2s;
  }

  .mobile_name {
    display: flex;
  }

  .mobile_chevron {
    color: var(--white);
    margin-left: auto;
    display: flex;
  }
}
</style>
